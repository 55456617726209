<template>
  <div>
    <h1>Flag search</h1>
    <div>
      <div>search:</div>
      <div><input v-model="input" /></div>
      <div>
        <div :key="data" v-for="data in found">
          <img :src="flag_url(data)" class="img_flag" />
          <!-- {{ data }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { FLAG_LIST } from "@/libs/flag_list";

const found = ref<string[]>([]);
const input = ref("");
const input_last = ref("");

const search = (text: string) => {
  //   console.log("search", text);

  const list: string[] = [];
  FLAG_LIST.forEach((v) => {
    //
    const pos = v.indexOf(text);
    if (pos < 0) return;
    list.push(v.substring(0, 3));
  });

  found.value = list;
};

const flag_url = (code: string) => {
  return "./flag/" + code + ".png";
};

let timer_id = 0;

onMounted(() => {
  input.value = "usa";

  timer_id = setInterval(() => {
    if (input_last.value == input.value) return;
    input_last.value = input.value;
    search(input.value);
  }, 1000);
});

onUnmounted(() => {
  clearInterval(timer_id);
  timer_id = 0;
});
</script>

<style scoped>
.img_flag {
  width: 100px;
  height: 70px;
}
</style>
