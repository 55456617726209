export const FLAG_LIST = [
  "brz:brz,brazil,브라질",
  "chn:china,중국 china",
  "cze:czech republic czech",
  "deu:germany,독일 germany",
  "eng:english,영어",
  "esp:spain,스페인 spain",
  "fra:france,프랑스 france",
  "gbr:united kingdom,영국 united kingdom",
  "gre:greece",
  "ind:india indonesia",
  "ita:italy italy",
  "jpn:japan japan",
  "kor:korea, dem. rep.,kr,한국,대한민국 republic of korea",
  "pol:poland poland",
  "prt:portugal portugal",
  "rou:bandeiras",
  "rsa:south africa:za",
  "rus:belarus russia",
  "swe:sweden sweden",
  "tha:thailand thailand",
  "tur:turkey turkey",
  "twn:taiwan twiwan",
  "ukr:ukraine ukraine",
  "usa:united states united states",
  "gha:ghana",
  "gab:gabon",
  "guy:guyana",
  "gmb:gambia",
  "ggy:guernsey",
  "glp:guadeloupe",
  "gtm:guatemala",
  "gum:guam",
  "grd:grenada",
  "grc:greece",
  "grl:greenland",
  "gin:guinea",
  "gnb:guinea",
  "nam:namibia",
  "nru:nauru",
  "nga:nigeria",
  "ata:antarctica",
  "zaf:south africa",
  "nld:netherlands",
  "ant:netherlands antilles",
  "npl:nepal",
  "nor:norway",
  "nfk:norfolk island",
  "ncl:new caledonia",
  "nzl:new zealand",
  "niu:niue",
  "ner:niger",
  "nic:nicaragua",
  "dnk:demark",
  "dma:dominica",
  "tls:east timor",
  "lao:lao",
  "lbr:liberia",
  "lva:latvia",
  "lbn:lebanon",
  "reu:reunion",
  "lux:luxembourg",
  "rwa:rwanda",
  "lby:libya",
  "ltu:lithuania",
  "lie:liechtenstein",
  "mdg:madagascar",
  "mtq:martinique",
  "mhl:marshall",
  "myt:mayotte",
  "mac:macao",
  "mwi:malawi",
  "mys:malaysia",
  "mli:mali",
  "imn:isle of man",
  "mex:mexico",
  "mco:monaco",
  "mar:morroco",
  "mus:mauritius",
  "mrt:mauritania",
  "moz:mozambique",
  "mne:montenegro",
  "msr:monteserrat",
  "mda:moldova",
  "mdv:maldives",
  "mlt:malta",
  "mng:mongolia",
  "vir:us virgin islands",
  "mmr:myanmar",
  "fsm:micronesia",
  "vut:vanuatu",
  "bhr:bahrain",
  "brb:barbados",
  "vat:vatican city",
  "bhs:bahamas",
  "bgd:bangladesh",
  "bmu:bermuda",
  "vgb:british virgin islands",
  "ben:benin",
  "ven:venezuela",
  "vnm:vietnam",
  "bel:belgium",
  "blr:belarus",
  "blz:belize",
  "bih:bosnia and herzegovina",
  "bwa:botswana",
  "bol:bolivia",
  "bdi:burundi",
  "bfa:burkina faso",
  "bvt:bouvet island",
  "btn:bhutan",
  "mnp:northern mariana islands",
  "mkd:macedonia",
  "prk:korea, democratic people's republic of",
  "bgr:bulgaria",
  "bra:brazil",
  "brn:brunei darussaiam",
  "wsm:western samoa",
  "sau:saudi arabia",
  "sgs:south georgia and the south sandwich islands",
  "smr:san marino",
  "stp:sao tome principe",
  "spm:st. pierre et miquelon",
  "esh:western sahara",
  "sen:senegal",
  "srb:serbia",
  "syc:seychelles",
  "lca:saint lucia",
  "vct:st. vincent and the grenadines",
  "kna:saint kitts and nevis",
  "shn:saint helena",
  "som:somali",
  "slb:solomon islands",
  "sdn:sudan",
  "sur:suriname",
  "lka:sri lanka",
  "sjm:svalbard and jan mayen",
  "swz:swaziland",
  "che:switzerland",
  "svk:slovakia",
  "svn:slovenia",
  "syr:syrian arab republic",
  "sle:sierra leone",
  "sgp:singapore",
  "are:united arab emirates",
  "abw:aruba island",
  "arm:armenia",
  "arg:argentina",
  "asm:american samoa",
  "isl:iceland",
  "hti:haiti",
  "irl:ireland",
  "aze:azerbaijan",
  "afg:afghanistan",
  "and:andorra",
  "alb:albania",
  "dza:algeria",
  "ago:angola",
  "atg:antigua and barbuda",
  "aia:anguilla",
  "eri:eritrea",
  "est:estonia",
  "ecu:ecuador",
  "eth:ethiopia",
  "slv:el salvador",
  "iot:british indian ocean territory",
  "yem:yemen",
  "omn:oman",
  "aut:austria",
  "hnd:honduras",
  "ala:ahvenanmaa",
  "wlf:wallis and futuna islands",
  "jor:jordan",
  "uga:uganda",
  "ury:uruguay",
  "uzb:uzbekistan",
  "irq:iraq",
  "irn:iran",
  "isr:israel",
  "egy:egypt",
  "idn:india",
  "jam:jamaica",
  "zmb:zambia",
  "jey:jersey",
  "gnq:equatorial gui",
  "geo:georgia",
  "caf:central african republic",
  "dji:djibouti",
  "gib:gibraltar",
  "zwe:zimbabwe",
  "tcd:chad",
  "chl:chile",
  "cmr:cameroon",
  "cpv:cape verde",
  "kaz:kazakhstan",
  "qat:qatar",
  "khm:cambodia",
  "can:canada",
  "ken:kenya",
  "cym:cayman islands",
  "com:comoros",
  "cri:costa rica",
  "cck:cocos(keeling) islands",
  "civ:cote d'lvoire",
  "col:colombia",
  "cog:republic of the congo",
  "cod:democratic republic of the congo",
  "cub:cuba",
  "kwt:kuwait",
  "cok:cook islands",
  "hrv:croatia",
  "cxr:christmas island",
  "kgz:kyrgyzstan",
  "kir:kiribati",
  "cyp:cyprus",
  "tjk:tajikistan",
  "tza:tanzania",
  "tca:turks and caicos islands",
  "tgo:togo",
  "tkl:tokelau",
  "ton:tonga",
  "tkm:turkmenistan",
  "tuv:tuvalu",
  "tun:tunisia",
  "tto:trinidad tobago",
  "pan:panama",
  "pry:paraguay",
  "pak:pakistan",
  "png:papua new guinea",
  "plw:palau",
  "pse:porisario",
  "fro:faeroe islands",
  "per:peru",
  "flk:falkland islands",
  "pri:puerto rico",
  "guf:french guiana",
  "atf:french southern territories",
  "pyf:french polynesia",
  "fji:fiji",
  "fin:finland",
  "phl:philippines",
  "pcn:pitcairn",
  "hmd:heard and mcdonald islands",
  "hun:hungary",
  "aus:australia",
  "hkg:hongkong",];